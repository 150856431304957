import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  FormControl,
  TextField,
  Grid,
  MenuItem,
  Avatar,
  Select,
  Box,
  TablePagination,
  IconButton,
  Menu,
  Button,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import DataTable from "../../Table/DataTable";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import Moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { GetCountries } from "react-country-state-city";

import { SESSION_AUTH } from "../../../helpers/auth";
import { getModuleAllPermissions } from "../../../helpers/utils";
import { useNavigate } from "react-router-dom";
import { ToastManager } from "../../common/ToastContainer";
import { EVENTS_SERVICES } from "../../../services";
import FullPageProgress from "../../common/FullPageProgress";
import { INVESTOR_TYPE_LIST } from "../../../helpers/constants";
import { EventUserCSV } from "./EventUserCSV";

export default function EventUser(props) {
  const { module } = props;
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [dataFetched, setDataFetched] = useState(false);
  const [userData, setUserData] = useState("");
  const [loader, setLoader] = useState(false);
  const [modulePermissions, setModulePermissions] = useState();
  const [userList, setUserList] = useState([]);

  const [totalRows, setTotalRows] = useState(0);

  const [selectedUser, setSelectedUser] = useState("");
  const [showFullText, setShowFullText] = useState("");
  const [countriesList, setCountriesList] = useState([]);

  const defaultFilter = {
    page: 0,
    limit: 10,
    name: "",
    email: "",
    phoneNumber: "",
    investorType: "",
    work: "",
    country: "",
    createdAtFrom: "",
    createdAtTo: "",
    jobTitle: "",
  };
  const [filter, setFilter] = useState(defaultFilter);

  const handleChangePage = (event, newPage) => {
    setFilter((old) => ({
      ...old,
      page: newPage,
    }));
    setDataFetched(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter((old) => ({
      ...old,
      limit: event.target.value,
      page: 0,
    }));
    setDataFetched(false);
  };

  const onChangeFilter = (name, value) => {
    let f = { ...filter };
    f[name] = value;
    setFilter(f);
  };
  const applyFilter = () => {
    setFilter((old) => ({
      ...old,
      page: 0,
    }));
    setDataFetched(false);
  };
  const clearFilter = () => {
    setFilter(defaultFilter);
    setDataFetched(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openUserChangeRow = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(item);
  };
  const closeUserChangeRow = () => {
    setAnchorEl(null);
  };
  const loginMagicLink = (type) => {
    setAnchorEl(null);
    if (!selectedUser) return;
    setLoader(true);
    let parma = {};
    parma["userId"] = userData.userId;
    parma["email"] = selectedUser.email;
    parma["name"] = selectedUser?.name;
    parma["type"] = type;

    EVENTS_SERVICES.loginMagicLink(parma)
      .then((data) => {
        if (data.code === 200) {
          if (data?.data?.link && type === "copy") {
            navigator.clipboard.writeText(data?.data?.link);
            ToastManager.addToast("Login Magic Link Copied!");
          } else {
            ToastManager.addToast("Sent Login Link on Email " + parma?.email);
          }
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (userSession) {
      setModulePermissions(getModuleAllPermissions(userSession, module));
      setUserData(userSession);
      GetCountries().then((result) => {
        setCountriesList(result);
      });
    } else {
      setModulePermissions("");
      setUserData("");
    }
    return () => null;
  }, [userSession, module]);

  useEffect(() => {
    if (!userData || dataFetched || !modulePermissions?.view) return;

    setLoader(true);
    setDataFetched(true);

    let parma = { ...filter };
    parma["userId"] = userData.userId;
    EVENTS_SERVICES.getUsers(parma)
      .then((data) => {
        setUserList([]);
        if (data.code === 200) {
          setUserList(data?.data?.list || []);
          setTotalRows(data?.data?.count || 0);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [userData, dataFetched, LOGOUT_USER_SESSION, modulePermissions, filter]);

  useEffect(() => {
    if (userData && !modulePermissions?.view) {
      return navigate("/");
    }
  }, [modulePermissions, userData, navigate]);

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },
    { name: "sno", label: "S.No." },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (item) => {
          return item?.value ? (
            <Link
              className="text-link"
              to={`/events/users/${item?.id}/profile`}
            >
              {item?.value}
            </Link>
          ) : null;
        },
      },
    },
    {
      name: "profileImage",
      label: "image",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <Avatar style={{ width: "40px" }} src={value} alt="" />
          ) : (
            "-"
          );
        },
      },
    },
    { name: "email", label: "Email" },
    { name: "phoneNumber", label: "Phone" },
    { name: "location", label: "Location" },
    { name: "jobTitle", label: "Position" },
    { name: "work", label: "Company" },
    { name: "lastActive", label: "Last Active" },
    { name: "createdAt", label: "Joined On" },
    {
      name: "activeStatus",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return <span className="text-status">{value}</span>;
        },
      },
    },
    { name: "investorType", label: "Investor Type" },
    { name: "companyWebsite", label: "Company Website" },
    {
      name: "bio",
      label: "Bio",
      options: {
        customBodyRender: (item) => {
          return showFullText === item.id ? (
            <div>
              {item?.text}{" "}
              {item?.text?.length > 50 ? (
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={() => setShowFullText("")}
                >
                  Less
                </Button>
              ) : null}
            </div>
          ) : item?.text ? (
            <div>
              {item?.text?.length > 50 ? (
                <>
                  {item?.text.slice(0, 50)} ...
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => setShowFullText(item?.id)}
                  >
                    More
                  </Button>
                </>
              ) : (
                item?.text
              )}
            </div>
          ) : null;
        },
      },
    },
    {
      name: "eventSpeaker",
      label: "Event Speaker",
      options: {
        customBodyRender: (item) => {
          return item?.value ? (
            <Link
              title="Event Speaker"
              className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary"
              to={`/events/users/${item?.id}/speakers-events`}
            >
              {item?.value}
            </Link>
          ) : null;
        },
      },
    },
    {
      name: "eventRegister",
      label: "Event Register",
      options: {
        customBodyRender: (item) => {
          return item?.value ? (
            <Link
              title="Event Register"
              to={`/events/users/${item?.id}/registered-events`}
              className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary"
            >
              {item?.value}
            </Link>
          ) : null;
        },
      },
    },
    {
      name: "eventAttend",
      label: "Event Attend",
      options: {
        customBodyRender: (item) => {
          return item?.value ? (
            <Link
              title="Event Attend"
              to={`/events/users/${item?.id}/attended-events`}
              className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary"
            >
              {item?.value}
            </Link>
          ) : null;
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (item) => {
          return (
            <>
              {modulePermissions?.add && (
                <div>
                  <IconButton
                    id={`user-dropdown-button-${item?._id}`}
                    aria-controls={
                      open ? `user-dropdown-menu-${item?._id}` : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) => openUserChangeRow(e, item)}
                  >
                    <MoreHorizIcon />
                  </IconButton>

                  <Menu
                    id={`user-dropdown-menu-${item?._id}`}
                    aria-labelledby={`user-dropdown-menu-${item?._id}`}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={closeUserChangeRow}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        loginMagicLink("email");
                      }}
                    >
                      <small> Send Login Magic Link via Email</small>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        loginMagicLink("copy");
                      }}
                    >
                      <small>Copy Login Magic Link</small>
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </>
          );
        },
      },
    },
  ];
  const customOption = {
    pagination: false,
    sort: false,
  };
  const convetFDate = (d, type) => {
    let date = new Date(d);
    if (type === "from") {
      date.setHours(0, 0, 0, 0);
    } else {
      date.setHours(23, 59, 59, 999);
    }
    return date;
  };

  return (
    <>
      {loader && <FullPageProgress />}
      <Box className="managepro6ix-layout-page-content">
        <div className="managepro6ix-layout-page-content-header">
          <div className="managepro6ix-layout-page-content-header-left">
            <div className="managepro6ix-layout-page-content-header-heading">
              6ix Users
            </div>
          </div>
          <div className="managepro6ix-layout-page-content-header-action">
            {userList?.length > 0 ? <EventUserCSV filter={filter} /> : null}
          </div>
        </div>
        <Accordion>
          <AccordionSummary
            sx={{ marginTop: 2, width: "100px" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ textTransform: "uppercase", fontSize: "14px" }}>
              Filters
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={{ xs: 1, md: 2 }}>
              {/* First Row */}
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Name"
                  value={filter?.name}
                  onChange={(e) => onChangeFilter("name", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Email"
                  value={filter?.email}
                  onChange={(e) => onChangeFilter("email", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  fullWidth
                  label="Phone"
                  value={filter?.phoneNumber}
                  onChange={(e) =>
                    onChangeFilter("phoneNumber", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Investor Type:
                  </InputLabel>
                  <Select
                    label="Status"
                    name="status"
                    value={filter?.investorType}
                    onChange={(e) =>
                      onChangeFilter("investorType", e.target.value)
                    }
                  >
                    {Object.keys(INVESTOR_TYPE_LIST).map((item, index) => (
                      <MenuItem
                        key={index}
                        value={INVESTOR_TYPE_LIST[item].value}
                      >
                        {INVESTOR_TYPE_LIST[item].name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  fullWidth
                  label="Company Name"
                  value={filter?.work}
                  onChange={(e) => onChangeFilter("work", e.target.value)}
                />
              </Grid>

              <Grid item xs={6} md={2}>
                <TextField
                  fullWidth
                  label="Position"
                  value={filter?.jobTitle}
                  onChange={(e) => onChangeFilter("jobTitle", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="fillter-country">Country</InputLabel>

                  <Select
                    id="fillter-country"
                    name="country"
                    className="custom-select"
                    value={filter?.country}
                    onChange={(e) => onChangeFilter("country", e.target.value)}
                    displayEmpty
                  >
                    {countriesList.map((item, key) => (
                      <MenuItem key={key} value={item?.iso2}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    label="Joined Date From"
                    format="MMM DD YYYY"
                    value={
                      filter?.createdAtFrom
                        ? dayjs(filter?.createdAtFrom)
                        : null
                    }
                    onChange={(newValue) => {
                      onChangeFilter(
                        "createdAtFrom",
                        newValue?.$d ? convetFDate(newValue?.$d, "from") : null
                      );
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    label="Joined Date To"
                    format="MMM DD YYYY"
                    value={
                      filter?.createdAtTo ? dayjs(filter?.createdAtTo) : null
                    }
                    onChange={(newValue) => {
                      onChangeFilter(
                        "createdAtTo",
                        newValue?.$d ? convetFDate(newValue?.$d, "to") : null
                      );
                    }}
                    minDate={dayjs(filter?.createdAtFrom)}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item sx={{ marginTop: 1 }}>
                <button
                  type="button"
                  className="managepro6ix-btn managepro6ix-btn-primary"
                  onClick={applyFilter}
                >
                  Search
                </button>
              </Grid>
              <Grid item sx={{ marginTop: 1 }}>
                <button
                  type="button"
                  className="managepro6ix-btn managepro6ix-btn-primary"
                  onClick={clearFilter}
                >
                  Clear
                </button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <div className="managepro6ix-table-center">
          <DataTable
            customOption={customOption}
            columns={columns}
            data={userList.map((item, key) => {
              return {
                action: item,
                sno: filter?.page * filter?.limit + key + 1,
                name: { id: item?._id, value: item?.name },
                profileImage: item.profileImage,
                email: item.email,
                phoneNumber: item.phoneNumber,
                jobTitle: item.jobTitle,
                work: item.work,
                lastActive: item?.lastActive
                  ? Moment(item?.lastActive).format("MMM DD, YYYY : hh:mm A")
                  : "-",
                createdAt: Moment(item?.createdAt).format(
                  "MMM DD, YYYY : hh:mm A"
                ),
                eventSpeaker: { id: item?._id, value: item?.eventSpeaker },
                eventRegister: { id: item?._id, value: item?.eventRegister },
                eventAttend: { id: item?._id, value: item?.eventAttend },
                location:
                  (item?.lastLogin?.city || "") +
                  " " +
                  (item?.lastLogin?.country || ""),
                activeStatus: item.activeStatus,
                companyWebsite: item.companyWebsite,
                investorType: item.investorType,
                bio: { text: item.bio, id: item?._id },
                id: item?._id,
              };
            })}
          />
        </div>
        <TablePagination
          component="div"
          count={totalRows}
          page={filter.page}
          onPageChange={handleChangePage}
          rowsPerPage={filter.limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
      </Box>
    </>
  );
}
